import React from "react";
import vertexEnvironmentPath from "assets/images/login/vertex/environment.png";
import customEnvironmentPath from "assets/images/login/custom/environment.png";
import paletteEnvironmentPath from "assets/images/login/environment.png";
import VertexColoredLogoWhiteText from "assets/icons/vertex/palette_logo_white_text.svg?react";
import SmallVertexLogo from "assets/icons/vertex/small_logo.svg?react";
import VertexColoredLogoColoredText from "assets/icons/vertex/palette_logo_colored_text.svg?react";
import VertexFullWhiteLogo from "assets/icons/vertex/palette_logo_full_white.svg?react";
import PaletteColoredLogoWhiteText from "assets/icons/logo_landscape_colored.svg?react";
import PaletteColoredLogoColoredText from "assets/icons/logo_landscape_colored_purple_text.svg?react";
import FullWhiteLogo from "assets/icons/logo_landscape_white.svg?react";
import SmallPaletteLogo from "assets/icons/brands/palette.svg?react";

import SmallLightCDWLogo from "assets/icons/cdw/small_light_logo.svg?react";
import CDWLightLogo from "assets/icons/cdw/light_logo.svg?react";
import CDWColoredLogo from "assets/icons/cdw/colored_logo.svg?react";

import appEnv from "services/app";
import { lavender, purple } from "utils/constants/colors";

export function useTheme({ isMenuExpanded = false, isAdminMode = false } = {}) {
  if (appEnv.env.theme?.variant === "CDW") {
    return {
      showSidebarBottomLogo: false,
      envBackgroundPath: paletteEnvironmentPath,
      authBackgroundGradient:
        "radial-gradient(157.74% 150.08% at -8.62% -17.47%, #b54aa1 0%, #653a89 50%, #090f2d 100%)",
      buttonBackground: "#150404",
      LightLogo: CDWLightLogo,
      ColoredLogo: CDWColoredLogo,
      ColoredLogoWhiteText: CDWLightLogo,
      SmallColoredLogo: CDWColoredLogo,
      SideMenuLogo: isMenuExpanded ? CDWLightLogo : SmallLightCDWLogo,
      customMenuLogoStyle: false,
      inputBorder: "#BCC3CD",
      secondaryButtonColor: "#150404",
      linkColor: "#206CD1",
      linkColorHover: "#206CD1",
      textColor: "#206cd1",
    };
  }

  if (appEnv.env.theme?.variant === "VERTEX") {
    return {
      showSidebarBottomLogo: true,
      authBackgroundGradient:
        "radial-gradient(157.74% 150.08% at -8.62% -17.47%, #3DA894 0%, #074648 50%, #090F2D 100%)",
      envBackgroundPath: vertexEnvironmentPath,
      buttonBackground: "linear-gradient(to right, #3575CF, #3DA894)",
      LightLogo: VertexFullWhiteLogo,
      ColoredLogo: VertexColoredLogoColoredText,
      ColoredLogoWhiteText: VertexColoredLogoWhiteText,
      SmallColoredLogo: SmallVertexLogo,
      SideMenuLogo: isMenuExpanded
        ? VertexColoredLogoWhiteText
        : SmallVertexLogo,
      customMenuLogoStyle: false,
      inputBorder: "#BCC3CD",
      secondaryButtonColor: "#206CD1",
      linkColor: "#206CD1",
      linkColorHover: "#206CD1",
      textColor: "#206cd1",
    };
  }

  if (appEnv.env.theme?.variant === "custom") {
    const themeData = appEnv.env.theme;
    if (appEnv.env.theme?.version === "v1") {
      return {
        showSidebarBottomLogo: true,
        envBackgroundPath: customEnvironmentPath,
        buttonBackground: themeData.colors.login,
        authBackgroundGradient:
          "linear-gradient(178.87deg, #141621 -14.31%, #353363 46.58%, #5e58b6 123.45%)",
        sidebarBackground: themeData.colors.sidebar,
        LightLogo: () => (
          <img alt="product logo" src={themeData.branding.sidebar} />
        ),
        ColoredLogo: () => (
          <img alt="product logo" src={themeData.branding.login} />
        ),
        ColoredLogoWhiteText: () => (
          <img alt="product logo" src={themeData.branding.sidebar} />
        ),
        SmallColoredLogo: () => (
          <img
            alt="tenant logo"
            src={themeData.branding.mark || themeData.branding.login}
          />
        ),
        SideMenuLogo: () => (
          <img alt="sidebar product logo" src={themeData.branding.sidebar} />
        ),
        inputBorder: themeData.colors.login,
        secondaryButtonColor: themeData.colors.login,
        linkColor: themeData.colors.login,
        linkColorHover: themeData.colors.login,
        textColor: themeData.colors.login,
        resizeOnCollapse: true,
      };
    }
  }

  return {
    showSidebarBottomLogo: true,
    envBackgroundPath: paletteEnvironmentPath,
    authBackgroundGradient:
      "radial-gradient(157.74% 150.08% at -8.62% -17.47%, #b54aa1 0%, #653a89 50%, #090f2d 100%)",
    buttonBackground:
      "linear-gradient(92.91deg, #5a53a4 5.15%, #8460b2 74.36%)",
    LightLogo: FullWhiteLogo,
    ColoredLogo: PaletteColoredLogoColoredText,
    ColoredLogoWhiteText: PaletteColoredLogoWhiteText,
    SmallColoredLogo: SmallPaletteLogo,
    SideMenuLogo: isAdminMode ? PaletteColoredLogoWhiteText : FullWhiteLogo,
    customMenuLogoStyle: true,
    inputBorder: purple,
    secondaryButtonColor: "#332e76",
    linkColor: "#4a4398",
    linkColorHover: "#332e76",
    textColor: lavender,
  };
}
