import history from "services/history";
import store from "services/store";
import { matchPath } from "react-router";
import i18next from "i18next";

import * as tasks from "utils/tasks";
import * as PATHS from "utils/constants/routes";
import { ADMIN_GUID, WORKLOADS_WITH_METRICS } from "utils/constants";
import api from "services/api";

import {
  getClusterByUid,
  getGaugeMetrics,
  fetchClusterProfile,
  attachClusterProfile,
  certsListActions,
  CERTS_MODULE,
  appliancesListActions,
  clusterAppliancesFetcher,
  refreshClusterSpecArch,
  fetchRepaveStatus,
} from "state/cluster/actions/details";
import {
  fetchRealTimeConsumptionData,
  getContextCurrentLock,
  availableClusterFiltersFetcher,
  overlordsFetcher,
  clusterFiltersOptionsFetcher,
} from "state/cluster/services";
import { initProjectOverview } from "state/projectoverview/actions";

import { openImportProcedureModal } from "state/cluster/actions/edit";
import {
  displayImportProcedure,
  getCluster,
} from "state/cluster/selectors/details";
import {
  fetchClusterAndNodes,
  fetchClusterEstimatedRate,
} from "state/cluster/actions/nodes";
import { fetchClusterAndPollEvents } from "state/cluster/actions/events";
import {
  cloudAccountsSummaryFetcher,
  clusterListActions,
} from "state/cluster/actions/list/clusters";
import { edgeMachinesListActions } from "state/cluster/actions/list/edgemachines";
import {
  virtualClustersListActionsDashboard,
  VIRTUAL_CLUSTERS_LIST_MODULE,
} from "state/cluster/actions/list/nestedclusters";

import {
  fetchRepositories,
  setAvailableRepositories,
} from "state/clusterprofile/actions/layerConfig";
import { setIsClusterProfileEdit } from "state/clusterprofile/actions/edit";
import { getClusterProfileByUid } from "state/clusterprofile/actions/details";
import {
  fetchRoles,
  projectRolesListActions,
  resourceRolesListActions,
  tenantRolesListActions,
  workspaceRolesListActions,
} from "state/roles/actions/list";
import {
  fetchBannerMessage,
  fetchCurrentUser,
  fetchTenantInfo,
  initAuthApp,
  initOidcFlow,
  orgsFetcher,
  redeemTenantToken,
  redirectToDefaultRoute,
  registerFormActions,
  selectProjectFromRoute,
  setPasswordFormActions,
} from "state/auth/actions";
import {
  projectFormActions,
  PROJECT_MODULE,
  projectModal,
  openCreateProjectModal,
} from "state/project/actions/create";
import { auditListActions } from "state/audit/actions";
import { openAccountModal } from "state/cloudaccounts/actions/create";
import { onCreateOverlordOpen } from "state/overlord/actions/create";
import {
  openOverlordConfigureModal,
  openSetOverlordNodesModal,
} from "state/overlord/actions/configure";
import {
  openPackRegistryModal,
  openHelmRegistryModal,
  openOciRegistryModal,
} from "state/packregistries/actions/create";
import {
  vmWareOverlordsFetcher,
  openstackOverlordsFetcher,
  maasOverlordsFetcher,
  CLOUD_ACCOUNTS_MODULE,
  cloudAccountsMetadataFetcher,
} from "state/cloudaccounts/services";
import { usersMetaFetcher } from "state/users/services";
import { overlordsListActions } from "state/overlord/actions/list";
import {
  availableOverlordsFiltersFetcher,
  OVERLORD_MODULE,
} from "state/overlord/services/list";
import { getRepositories } from "state/clusterprofile/selectors/layerConfig";
import {
  initCpuCoresChart,
  projectListActions,
} from "state/project/actions/list";
import {
  coresChartTimePeriodInit,
  monthlyCoreHoursChartCalendarChange,
} from "state/project/actions/metrics";
import { ssoIdpsFetcher, defaultTeamsFetcher } from "state/sso/services";
import {
  profileBuilderEditModule,
  profileListActions,
  deleteProfileModal,
} from "state/clusterprofile/services";
import { systemProfileBuilderEditModule } from "state/profiles/system/services/create";
import { getCurrentUser, getFreemiumUsage } from "state/auth/selectors";
import { overlordFetcher, IPAM_MODULE } from "state/overlord/services/ipam";
import { ipamListActions } from "state/overlord/actions/ipam";
import { openSSHKeyModal } from "state/sshKeys/actions/create";
import { invoicesFetcher } from "state/billingdetails/services";
import { initAllBillingData } from "state/plandetails/actions";
import { ClusterSchema } from "utils/schemas";
import { dnsListActions } from "state/dns/actions/list";
import { DNS_MAPPING_MODULE } from "state/dns/services";
import {
  loadClusterScan,
  scansCountFetcher,
  watchScansTask,
} from "state/cluster/actions/scan";
import {
  backupLocationsListActions,
  openBackupLocationModal,
  BACKUP_LOCATIONS_MODULE,
} from "state/backuplocations/actions";
import {
  CERTS_LIST_MODULE,
  certificatesListActions,
  openCertificateModal,
} from "state/certificates/actions";
import {
  restoresListActions,
  backupsListActions,
  RESTORE_LIST_MODULE,
  BACKUP_LIST_MODULE,
} from "state/backups/services";
import { samlFormActions } from "state/sso/saml/actions";
import { oidcFormActions } from "state/sso/oidc/actions";
import { domainsFormActions } from "state/domains/actions";
import { getEnabledSSO } from "state/sso/selectors";
import { initClusterCosts } from "state/cluster/actions/costs";
import { AUDIT_TRAILS_LIST_MODULE } from "state/audittrails/services";
import {
  auditTrailsListActions,
  openAuditTrailModal,
} from "state/audittrails/actions";
import {
  projectEmailAlertsFormActions,
  projectWebhookAlertsFormActions,
  webhooksAlertsListing,
} from "state/alerts/actions";
import {
  EMAIL_ALERTS_MODULE,
  webhookAlertModal,
  WEBHOOK_ALERTS_MODULE,
} from "state/alerts/services";
import {
  onOrganizationChartsCalendarChange,
  organizationFilterFormActions,
} from "state/organization/actions";
import { ORGANIZATION_OVERVIEW_FILTERS_MODULE } from "state/organization/services";
import { workspaceListActions } from "state/workspaces/actions/listing";
import {
  filtersFormActions,
  FILTERS_FORM_MODULE,
} from "state/workspaces/actions/listing";
import { getWorkspaceByUid } from "state/workspaces/actions/details";
import { initializeWorkloadsByTab } from "state/workspaces/services/workloads";
import {
  workspaceRestoresListActions,
  workspaceBackupsListActions,
  WORKSPACE_RESTORE_LIST_MODULE,
  WORKSPACE_BACKUP_LIST_MODULE,
} from "state/workspaces/services/backups";
import {
  workspaceBackupsListActions as clusterWorkspaceBackupsListActions,
  workspaceRestoresListActions as clusterGroupRestoresListActions,
  WORKSPACE_BACKUP_LIST_MODULE as CLUSTER_WORKSPACE_BACKUP_LIST_MODULE,
  WORKSPACE_RESTORE_LIST_MODULE as CLUSTER_GROUP_RESTORE_LIST_MODULE,
} from "state/clustergroups/services/backups";
import {
  onPodsChartPeriodChange as onWorkspacePodsPeriodChange,
  workloadsFilters,
  WORKLOADS_FILTERS_MODULE,
} from "state/workspaces/actions/workloads";
import { initWorkspacesUsageCost } from "state/workspaces/actions/usagecost";
import {
  initializeWorkloadsTab,
  workloadsFilterFormActions,
} from "state/cluster/actions/workloads";
import { onChartPeriodChange } from "state/cluster/actions/workloads";
import { namespacesNamesFetcher } from "state/cluster/services/workloads/namespaces";
import { cloudRatesFormActions } from "state/cloudrates/actions";
import {
  applianceProfilesStackModule,
  edgeMachineDetailsFetcher,
  EDGE_MACHINES_MODULE,
  APPLIANCE_EVENTS_LIST_MODULE,
  applianceEventsListActions,
  tagsCache,
} from "state/cluster/services/edgemachines";
import { apikeysListActions, openApiKeyModal } from "state/apikeys/actions";
import { API_KEYS_LIST_MODULE } from "state/apikeys/services";
import {
  ADMIN_PREFIX,
  ADMIN_PREFIX_WITH_PROJECT,
  DEV_PREFIX_WITH_PROJECT,
  PROJECT_PREFIX,
} from "components/common/History/Route";
import {
  CLOUD_SELECTOR_MODULE,
  cloudSelectFormActions,
  selectCloudModal,
} from "state/selectCloud/services";
import {
  wizardActions,
  CLUSTER_CREATION_STEPS,
  initClusterWizard,
} from "state/cluster/actions/create/common";
import { macrosFormActions, MACROS_FORM_MODULE } from "state/macros/actions";
import { getRawClusterProfile } from "state/clusterprofile/selectors/details";
import { fetchClusterProfile as fetchClusterProfileByUid } from "state/clusterprofile/actions/details";
import { usersListActions, USERS_MODULE } from "state/users/actions";
import { initMapViewData } from "state/cluster/actions/mapview";
import { openMigrateOverlordModal } from "state/overlord/actions/migrate";

import { virtualClustersListActions as clusterGroupVirtualClustersListActions } from "state/clustergroups/actions/nestedclusters/listing";
import { VIRTUAL_CLUSTERS_LIST_MODULE as CLUSTER_GROUP_VIRTUAL_CLUSTERS_LIST_MODULE } from "state/clustergroups/services/listing";
import {
  fetchAllDashboardClusters,
  fetchClusterGroupProfiles,
  getClusterGroupByUid,
} from "state/clustergroups/actions/details";

import {
  clusterGroupsListActions,
  clusterGroupsFiltersFormActions,
} from "state/clustergroups/actions/listing";
import {
  CLUSTER_GROUPS_LIST_MODULE,
  CLUSTER_GROUPS_FILTERS_MODULE,
  HOST_CLUSTERS_LIST_MODULE,
} from "state/clustergroups/services/listing";
import { hostClustersListActions } from "state/clustergroups/actions/hostclusters/listing";
import { initializeClusterGroupWorkloadsByTab } from "state/clustergroups/services/workloads";
import {
  clusterGroupWorkloadsFilters,
  CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
  onClusterGroupPodsPeriodChange,
} from "state/clustergroups/actions/workloads";
import { closeKubectlModal } from "state/cluster/actions/details";
import {
  resourceLimitsFormActions,
  RESOURCE_LIMITS_FORM_MODULE,
} from "state/resourcelimits/actions";
import {
  cloudAccountsListActions,
  fetchAllCloudAccounts,
} from "state/cloudaccounts/actions/list";
import { providersFormActions } from "state/sso/providers/actions";
import { getPathForValue } from "utils/objects";
import { initVirtualClusterWizard } from "state/nestedclusters/actions/create";
import { initClusterGroupWizard } from "state/clustergroups/actions/create/wizardactions";
import { applicationProfileCreator } from "modules/profileIDE/flows/application-create";

import {
  appProfilesListActions,
  APP_PROFILES_MODULE,
} from "state/appprofiles/actions/listing";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { operatorsFetcher } from "modules/profileIDE/state/operators";
import { initializeAppDetailPage } from "state/appprofiles/actions/details";

import {
  appDeploymentsListActions,
  appDeploymentsListActionsForDashboard,
  DEPLOYMENTS_LIST_MODULE,
} from "state/appdeployments/services/listings";
import { initDeploymentCreationForm } from "state/appdeployments/actions/create";
import { fetchAppDeploymentByUid } from "state/appdeployments/services/details";
import {
  virtualClusterFormActions,
  VIRTUAL_CLUSTER_FORM_MODULE,
} from "state/devnestedcluster/actions/create";
import { helmRepositoriesFetcher } from "modules/profileIDE/state/packs";
import { platformClusterSettingsFetcher } from "state/nodesautoremediation/services";
import { systemClusterGroupsVisibilityFetcher } from "state/systemclustergroups/services";
import { fetchAllClusters } from "state/cluster/actions/mapview";
import {
  appProfileMacrosFetcher,
  packsUrlFetcher,
} from "modules/profileIDE/state/editor/services";
import {
  developerQuotaUsageFetcherSystemScope,
  developerQuotaUsageFetcherTenantScope,
  sandboxClusterQuotaUsageFetcher,
} from "state/devnestedcluster/services";
import { containersFetcher } from "modules/profileIDE/state/containers";
import {
  filtersListingActions,
  FILTERS_LISTING_MODULE,
} from "state/filters/services";
import { messagingSystemsFetcher } from "modules/profileIDE/state/messagingSystems";
import { storageOptionsFetcher } from "modules/profileIDE/state/storage";
import { ROLES_MODULE } from "state/roles/actions/create";
import {
  virtualClustersListingActions,
  VIRTUAL_CLUSTERS_MODULE as WORKLOAD_VIRTUAL_CLUSTERS_MODULE,
} from "state/cluster/services/workloads/nestedclusters";
import { hasAppProfileUnsavedChanges } from "state/appprofiles/selectors/edit";
import { openRegistrationTokenModal } from "state/registrationtokens/actions";
import { securityOptionsFetcher } from "modules/profileIDE/state/security";
import { virtualClustersListActions } from "state/cluster/actions/list/nestedclusters";
import { atoMessageFormActions } from "state/atoMessage/actions";
import { nonFipsSettingsFetcher } from "state/nonFipsSettings/services";
import { sshKeysListActions } from "state/sshKeys/actions/list";
import { SSH_KEYS_MODULE } from "state/sshKeys/services";
import { repaveStatusFetcher } from "state/projectoverview/services";
import { onRepaveStatusRedirect } from "state/cluster/actions/filters";
import { clusterRevisionsFetcher } from "state/cluster/services/details";
import { DeviceSelection } from "pages/clusters/nodes/flows/baremetal/EdgeNativeNodePool";
import {
  helmRegistriesActions,
  ociRegistriesActions,
  packRegistriesActions,
} from "state/packregistries/actions/list";
import {
  HELM_MODULE,
  OCI_MODULE,
  PACK_MODULE,
} from "state/packregistries/services";

async function loadVirtualClusters() {
  await store.dispatch(
    virtualClustersListActions.initialize(VIRTUAL_CLUSTERS_LIST_MODULE)
  );
  tasks.pollVirtualClusters.start();
}

function routeToOverviewClusterPage() {
  const hasFreemiumOverUsage =
    getFreemiumUsage(store.getState())?.usagePercent >= 100;

  if (hasFreemiumOverUsage) {
    history.push("/clusters/overview");
    return;
  }
}

function loadRegistries({ type } = {}) {
  if (type === "helm") {
    store.dispatch(helmRegistriesActions.initialize(HELM_MODULE));
  }
  if (type === "oci") {
    store.dispatch(ociRegistriesActions.initialize(OCI_MODULE));
  }

  if (type === "pack") {
    store.dispatch(packRegistriesActions.initialize(PACK_MODULE));
  }

  tasks.pollRegistries.start();
}

async function clusterOverview({ id, clusterCategory }) {
  store.dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE" });
  store.dispatch(sandboxClusterQuotaUsageFetcher.flushCache());
  store.dispatch(getGaugeMetrics(id));
  store.dispatch(fetchRealTimeConsumptionData(id));
  await store.dispatch(fetchClusterAndNodes(id));
  store.dispatch(fetchRepaveStatus(id));

  store.dispatch(refreshClusterSpecArch(id));

  store.dispatch(fetchClusterProfile());

  if (clusterCategory === "privatecloudgateways") {
    store.dispatch(overlordsFetcher.fetch());
  }

  tasks.poolClusterErrors.start();
  tasks.pollClusterStatus.start();
  tasks.pollClusterNotifications.start();
  if (displayImportProcedure(store.getState())) {
    store.dispatch(openImportProcedureModal());
  }
}

const MAP = {
  [PATHS.ROOT]: () => {
    const user = getCurrentUser(store.getState());
    if (user) {
      store.dispatch(redirectToDefaultRoute());
    }
  },
  [PATHS.CLUSTER_PROFILES.ROOT]: async () => {
    store.dispatch(nonFipsSettingsFetcher.fetch());
    store.dispatch(profileListActions.initialize("clusterprofiles"));
  },
  [PATHS.AUTH.ROOT]: async () => {
    store.dispatch({ type: "APP_LOADING", isLoading: true });
    await store.dispatch(fetchTenantInfo());
    await store.dispatch(fetchBannerMessage());
    store.dispatch({ type: "APP_LOADING", isLoading: false });
    store.dispatch(initAuthApp());
    store.dispatch({
      type: "SET_LOGIN_STEP",
      step: "login",
    });
  },

  [PATHS.AUTH.OIDC_LOGIN]: ({ token }) => {
    if (token === "success") {
      return;
    }
    store.dispatch(initOidcFlow());
  },

  [PATHS.AUTH.DEVX_SIGNUP]: () => {
    store.dispatch(initAuthApp());
    store.dispatch(registerFormActions.init({ module: "register" }));
  },

  [PATHS.AUTH.SIGNUP]: () => {
    store.dispatch(initAuthApp());
    store.dispatch(registerFormActions.init({ module: "register" }));
  },

  [PATHS.AUTH.ORG_SELECT]: () => {
    store.dispatch(initAuthApp());
    store.dispatch(fetchCurrentUser());
    store.dispatch(orgsFetcher.fetch());
  },

  [PATHS.AUTH.PASSWORD_ACTION]: async () => {
    await store.dispatch(redeemTenantToken());
    store.dispatch(setPasswordFormActions.init({ module: "setPassword" }));
  },
  [PATHS.AUTH.FORGOT_PASSWORD]: async () => {
    store.dispatch(initAuthApp());
    store.dispatch({
      type: "SET_LOGIN_STEP",
      step: "resetPassword",
    });
  },
  [PATHS.CLUSTER_PROFILES.DETAILS]: async ({ id }) => {
    if (id === "create") {
      return;
    }
    await store.dispatch(getClusterProfileByUid(id));
    await store.dispatch(fetchRepositories());
    store.dispatch(setIsClusterProfileEdit(true));
    const repositories = getRepositories(store.getState());
    store.dispatch(setAvailableRepositories(repositories));
  },
  [PATHS.ORGANIZATION_OVERVIEW.ROOT]: async () => {
    store.dispatch(fetchAllClusters({ init: true }));
    store.dispatch(repaveStatusFetcher.fetch());

    await Promise.allSettled([
      store.dispatch(
        organizationFilterFormActions.init({
          module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        })
      ),
      projectListActions.initialize(PROJECT_MODULE),
    ]);

    store.dispatch(onOrganizationChartsCalendarChange());
  },
  [PATHS.AUDIT.ROOT]: () => {
    auditListActions.initialize("auditlogs");
    store.dispatch(usersMetaFetcher.fetch());
  },
  [PATHS.CLUSTERS.APPLIANCES_OVERVIEW]: async ({ id, tab }) => {
    if (tab === "overview") {
      const machine = await store.dispatch(edgeMachineDetailsFetcher.fetch(id));
      const profiles = machine.spec.clusterProfileTemplates.map((template) => ({
        ...template,
        metadata: {
          uid: template.uid,
          name: template.name,
        },
        spec: {
          published: {
            ...template,
            packs: (template?.packs || []).map((pack) => {
              return {
                metadata: { uid: pack.packUid, name: pack.name },
                spec: { ...pack },
                ...pack,
              };
            }),
          },
        },
      }));
      applianceProfilesStackModule.actions.initialize({ profiles });
    }

    if (tab === "events") {
      store.dispatch(
        applianceEventsListActions.initialize(APPLIANCE_EVENTS_LIST_MODULE)
      );
      store.dispatch(edgeMachineDetailsFetcher.fetch(id));
      tasks.pollApplianceEvents.start();
    }
  },

  [PATHS.CLUSTERS.IMPORT_CLUSTER]: () => {
    routeToOverviewClusterPage();
  },

  [PATHS.CLUSTERS.SELECT_CLOUD]: async () => {
    routeToOverviewClusterPage();
    selectCloudModal.open();
    await cloudSelectFormActions.init({ module: CLOUD_SELECTOR_MODULE });
    store.dispatch(cloudAccountsMetadataFetcher.fetch());
  },

  [PATHS.CLUSTERS.SCAN_DETAILS]: scanDetailsTabObserver,
  [PATHS.CLUSTER_GROUPS.SCAN_DETAILS]: scanDetailsTabObserver,
  [PATHS.CLUSTERS.ROOT]: async ({ tab }) => {
    store.dispatch(nonFipsSettingsFetcher.fetch());
    store.dispatch(cloudAccountsSummaryFetcher.fetch());
    async function loadClusters() {
      await store.dispatch(clusterFiltersOptionsFetcher.fetch());
      await store.dispatch(availableClusterFiltersFetcher.fetch());
      const context = store.getState().auth.currentProjectId;
      await store.dispatch(
        clusterListActions.initialize(`clusters/${context}`)
      );

      const repaveStatus = history.getQuery()?.repaveStatus;
      if (repaveStatus) {
        onRepaveStatusRedirect(repaveStatus);
      }

      store.dispatch(initMapViewData());
      tasks.pollSpectroClusters.start();
    }

    if (tab === "overview") {
      await loadClusters();
    }

    if (tab === "virtual") {
      await loadVirtualClusters();
    }

    if (tab === "appliances") {
      store.dispatch(tagsCache.flushCache());
    }

    await store.dispatch(
      edgeMachinesListActions.initialize(EDGE_MACHINES_MODULE)
    );
    tasks.pollAppliances.start();
  },
  [PATHS.CLUSTERS.TAB_DETAILS]: clusterDetailsObserver,
  [PATHS.CLUSTER_GROUPS.CLUSTER_GROUP_DETAILS]: clusterDetailsObserver,
  [PATHS.CLUSTERS.CREATE_CLUSTER]: async () => {
    await store.dispatch(initClusterWizard());
    const steps = store.getState().wizard?.cluster?.steps;
    if (steps?.find((step) => step.id === "parameters")) {
      store.dispatch(
        wizardActions.updateSteps(
          "cluster",
          CLUSTER_CREATION_STEPS.filter((step) => step.id !== "parameters")
        )
      );
    }
  },
  [PATHS.CLUSTERS.NEW_VIRTUAL_CLUSTER]: () => {
    store.dispatch(initVirtualClusterWizard());
  },
  [PATHS.CLUSTERS.CREATE_CLUSTER_WITH_SELECTED_PROFILE]: async ({
    profileUid,
  }) => {
    await store.dispatch(initClusterWizard());
    await store.dispatch(fetchClusterProfileByUid(profileUid));
    const clusterprofile = getRawClusterProfile(store.getState());
    const steps = store.getState().wizard?.cluster?.steps;

    if (clusterprofile.spec.published.type === "add-on") {
      return;
    }

    if (steps?.find((step) => step.id === "infra-profile")) {
      store.dispatch(
        wizardActions.updateSteps(
          "cluster",
          CLUSTER_CREATION_STEPS.filter((step) => step.id !== "infra-profile")
        )
      );
    }
  },
  [PATHS.CLUSTERS.CERTIFICATES]: certificatesObserver,
  [PATHS.CLUSTER_GROUPS.CERTIFICATES]: certificatesObserver,
  [PATHS.MANAGEMENT.ROOT]: ({ tab }) => {
    if (tab === "users") {
      store.dispatch(fetchRoles());
    }

    if (tab === "teams") {
      store.dispatch(usersMetaFetcher.fetch());
    }
  },
  [PATHS.ROLES.ROOT]: ({ tab }) => {
    const listingActions = {
      project: projectRolesListActions,
      tenant: tenantRolesListActions,
      workspace: workspaceRolesListActions,
      resource: resourceRolesListActions,
    };

    store.dispatch(listingActions[tab].initialize(ROLES_MODULE));
  },
  [PATHS.SETTINGS.CLOUD_ACCOUNTS]: async () => {
    await store.dispatch(
      cloudAccountsListActions.initialize(CLOUD_ACCOUNTS_MODULE)
    );
    store.dispatch(nonFipsSettingsFetcher.fetch());
    store.dispatch(fetchAllCloudAccounts());
  },
  [PATHS.SETTINGS.CREATE_CLOUD_ACCOUNT]: ({ cloudType }) => {
    store.dispatch(openAccountModal({ cloudType }));
    if (cloudType === "openstack") {
      store.dispatch(openstackOverlordsFetcher.fetch());
    }
    if (cloudType === "vsphere") {
      store.dispatch(vmWareOverlordsFetcher.fetch());
    }
    if (cloudType === "maas") {
      store.dispatch(maasOverlordsFetcher.fetch());
    }
  },
  [PATHS.SETTINGS.CLOUD_ACCOUNT]: ({ cloudType, uid }) => {
    if (uid === "create") {
      return;
    }
    store.dispatch(openAccountModal({ cloudType, uid, viewMode: true }));
  },
  [PATHS.SETTINGS.CLOUD_RATES]: ({ cloudType, uid }) => {
    store.dispatch(cloudRatesFormActions.init({ module: "cloudrates" }));
  },
  [PATHS.SETTINGS.RESOURCE_LIMITS]: () => {
    store.dispatch(
      resourceLimitsFormActions.init({ module: RESOURCE_LIMITS_FORM_MODULE })
    );
  },
  [PATHS.SETTINGS.EDIT_CLOUD_ACCOUNT]: ({ cloudType, uid }) => {
    store.dispatch(openAccountModal({ cloudType, uid }));
    if (cloudType === "openstack") {
      store.dispatch(openstackOverlordsFetcher.fetch());
    }
    if (cloudType === "vsphere") {
      store.dispatch(vmWareOverlordsFetcher.fetch());
    }
    if (cloudType === "maas") {
      store.dispatch(maasOverlordsFetcher.fetch());
    }
  },
  [PATHS.PROJECTS.ROOT]: async () => {
    store.dispatch(coresChartTimePeriodInit());
    await store.dispatch(projectListActions.initialize(PROJECT_MODULE));
    store.dispatch(monthlyCoreHoursChartCalendarChange());

    await store.dispatch(
      organizationFilterFormActions.init({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
      })
    );
    store.dispatch(initCpuCoresChart());
  },
  [PATHS.PROJECTS.CREATE_PROJECT]: () =>
    store.dispatch(openCreateProjectModal()),
  [PATHS.PROJECTS.EDIT_PROJECT]: async ({ id }) => {
    projectModal.open({ id, modalType: "edit" }).then(
      () => {
        return store.dispatch(
          projectFormActions.submit({ module: PROJECT_MODULE })
        );
      },
      () => {
        history.push(PATHS.PROJECTS.ROOT);
      }
    );
    store.dispatch(projectFormActions.init({ module: PROJECT_MODULE }));
  },
  [PATHS.SETTINGS.PRIVATE_CLOUD_GATEWAYS]: () => {
    store.dispatch(availableOverlordsFiltersFetcher.fetch());
    store.dispatch(overlordsListActions.initialize(OVERLORD_MODULE));
    tasks.pollDatacenters.start();
  },
  [PATHS.SETTINGS.CREATE_PRIVATE_CLOUD_GATEWAY]: ({ cloudType }) => {
    store.dispatch(nonFipsSettingsFetcher.fetch());
    store.dispatch(onCreateOverlordOpen(cloudType));
  },
  [PATHS.SETTINGS.EDIT_PRIVATE_CLOUD_GATEWAY]: ({ cloudType, uid }) => {
    store.dispatch(openOverlordConfigureModal(uid, cloudType));
  },
  [PATHS.SETTINGS.PRIVATE_CLOUD_GATEWAY_NODES]: ({ cloudType, uid }) => {
    store.dispatch(openSetOverlordNodesModal(uid, cloudType));
  },
  [PATHS.SETTINGS.PRIVATE_CLOUD_GATEWAY_MIGRATE]: ({ cloudType, uid }) => {
    store.dispatch(openMigrateOverlordModal(uid, cloudType));
  },

  [PATHS.PRIVATE_CLOUD_GATEWAYS.IPAM_DNS_TABS]: ({ id, type, uid, tab }) => {
    if (id === "system") {
      store.dispatch({
        type: "FETCH_CLUSTER",
        promise: Promise.resolve({
          metadata: {
            name: "System Private Gateway",
            uid: "system",
            annotations: {
              overlordUid: uid,
            },
          },
          spec: {
            cloudType: type,
          },
        }),
        schema: ClusterSchema,
      });
    } else {
      store.dispatch(fetchClusterAndPollEvents(id));
    }
    if (tab === "ipam") {
      store.dispatch(ipamListActions.initialize(IPAM_MODULE));
    }
    if (tab === "dns") {
      store.dispatch(dnsListActions.initialize(DNS_MAPPING_MODULE));
    }
    store.dispatch(overlordFetcher.fetch());
  },

  [PATHS.SETTINGS.REGISTRIES]: ({ tab }) => {
    loadRegistries({ type: tab });
  },
  [PATHS.SETTINGS.EDIT_REGISTRY]: ({ tab, uid, ociType }) => {
    if (tab === "pack") {
      store.dispatch(openPackRegistryModal(uid));
    }
    if (tab === "helm") {
      store.dispatch(openHelmRegistryModal(uid));
    }
    if (tab === "oci") {
      store.dispatch(openOciRegistryModal(uid, ociType));
    }
  },
  [PATHS.SETTINGS.CREATE_REGISTRY]: ({ tab }) => {
    if (tab === "pack") {
      store.dispatch(openPackRegistryModal());
    }
    if (tab === "helm") {
      store.dispatch(openHelmRegistryModal());
    }
    if (tab === "oci") {
      store.dispatch(openOciRegistryModal());
    }
  },

  [PATHS.SETTINGS.SSO]: async () => {
    if (
      Object.keys(store.getState().forms?.saml?.initialData || {}).length === 0
    ) {
      store.dispatch(ssoIdpsFetcher.fetch());
      store.dispatch(defaultTeamsFetcher.fetch());
      await store.dispatch(samlFormActions.init({ module: "saml" }));
      await store.dispatch(oidcFormActions.init({ module: "oidc" }));

      const enabledSso = getEnabledSSO(store.getState());

      store.dispatch({
        type: "SSO_TOGGLE",
        enabledSso,
      });

      store.dispatch(domainsFormActions.init({ module: "domains" }));
      store.dispatch(providersFormActions.init({ module: "providers" }));
    }
  },
  [PATHS.SETTINGS.SSH_KEYS]: () =>
    store.dispatch(sshKeysListActions.initialize(SSH_KEYS_MODULE)),
  [PATHS.SETTINGS.CREATE_SSH_KEY]: () => store.dispatch(openSSHKeyModal()),
  [PATHS.SETTINGS.EDIT_SSH_KEY]: ({ uid }) =>
    store.dispatch(openSSHKeyModal(uid)),

  [PATHS.SETTINGS.BILLING_DETAILS]: () => {
    store.dispatch(initAllBillingData());
    store.dispatch(invoicesFetcher.fetch());
  },
  [PATHS.SETTINGS.PLAN_DETAILS]: () => {
    store.dispatch(initAllBillingData());
  },
  [PATHS.SETTINGS.BACKUP_LOCATIONS]: () => {
    store.dispatch(
      backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE)
    );
  },

  [PATHS.SETTINGS.CREATE_BACKUP_LOCATION]: () =>
    store.dispatch(openBackupLocationModal()),
  [PATHS.SETTINGS.EDIT_BACKUP_LOCATION]: ({ uid, provider }) =>
    store.dispatch(openBackupLocationModal({ uid, provider })),
  [PATHS.SETTINGS.ALERTS]: ({ tab }) => {
    if (tab === "email") {
      store.dispatch(
        projectEmailAlertsFormActions.init({ module: EMAIL_ALERTS_MODULE })
      );
    }

    if (tab === "webhooks") {
      store.dispatch(webhooksAlertsListing.initialize(WEBHOOK_ALERTS_MODULE));
    }
  },
  [PATHS.SETTINGS.CREATE_WEBHOOK_ALERT]: () =>
    webhookAlertModal.open().then(
      async () => {
        await store.dispatch(
          projectWebhookAlertsFormActions.submit({
            module: WEBHOOK_ALERTS_MODULE,
          })
        );
        history.push("/settings/alerts/webhooks");
      },
      () => history.push("/settings/alerts/webhooks")
    ),
  "/settings/certificates": () => {
    store.dispatch(certificatesListActions.initialize(CERTS_LIST_MODULE));
  },
  "/settings/certificates/create": () => store.dispatch(openCertificateModal()),
  "/settings/certificates/:uid/edit": ({ uid }) =>
    store.dispatch(openCertificateModal(uid)),

  [PATHS.SETTINGS.AUDIT_TRAILS]: () => {
    store.dispatch(auditTrailsListActions.initialize(AUDIT_TRAILS_LIST_MODULE));
  },
  [PATHS.SETTINGS.API_KEYS]: () => {
    store.dispatch(apikeysListActions.initialize(API_KEYS_LIST_MODULE));
    store.dispatch(usersMetaFetcher.fetch());
  },
  [PATHS.SETTINGS.CREATE_AUDIT_TRAIL]: () => {
    store.dispatch(openAuditTrailModal());
  },
  [PATHS.SETTINGS.CREATE_API_KEY]: async () => {
    store.dispatch(openApiKeyModal());
    store.dispatch(usersListActions.initialize(USERS_MODULE));
  },
  [PATHS.SETTINGS.EDIT_API_KEY]: ({ uid }) => {
    store.dispatch(openApiKeyModal(uid));
  },
  [PATHS.SETTINGS.EDIT_AUDIT_TRAIL]: ({ uid }) => {
    store.dispatch(openAuditTrailModal(uid));
  },
  [PATHS.SETTINGS.MACROS]: () => {
    store.dispatch(macrosFormActions.init({ module: MACROS_FORM_MODULE }));
  },
  [PATHS.SETTINGS.PLATFORM_SETTINGS]: async () => {
    store.dispatch(getContextCurrentLock.fetch());
    store.dispatch(platformClusterSettingsFetcher.fetch());
    store.dispatch(nonFipsSettingsFetcher.fetch());
    store.dispatch(atoMessageFormActions.init({ module: "atoMessage" }));
  },
  [PATHS.SETTINGS.REGISTRATION_TOKENS]: () => {
    store.dispatch(usersListActions.initialize(USERS_MODULE));
  },
  [PATHS.SETTINGS.CREATE_REGISTRATION_TOKEN]: () => {
    store.dispatch(openRegistrationTokenModal());
  },
  [PATHS.SETTINGS.EDIT_REGISTRATION_TOKEN]: ({ uid }) => {
    store.dispatch(openRegistrationTokenModal(uid));
  },
  [PATHS.SETTINGS.DEVELOPER_SETTINGS]: async () => {
    store.dispatch(systemClusterGroupsVisibilityFetcher.fetch());
  },
  [PATHS.SETTINGS.FILTERS]: async () => {
    store.dispatch(filtersListingActions.initialize(FILTERS_LISTING_MODULE));
  },
  [PATHS.PROJECT_OVERVIEW.ROOT]: () => {
    store.dispatch(initProjectOverview());
    store.dispatch(repaveStatusFetcher.fetch());
  },
  [PATHS.WORKSPACES.ROOT]: ({ tab }) => {
    if (tab === "overview") {
      store.dispatch(workspaceListActions.initialize("workspaces"));
      store.dispatch(filtersFormActions.init({ module: FILTERS_FORM_MODULE }));
    }

    if (tab === "usagecost") {
      store.dispatch(initWorkspacesUsageCost());
    }
  },
  [PATHS.CLUSTER_GROUPS.ROOT]: () => {
    store.dispatch(
      clusterGroupsListActions.initialize(CLUSTER_GROUPS_LIST_MODULE)
    );
    store.dispatch(
      clusterGroupsFiltersFormActions.init({
        module: CLUSTER_GROUPS_FILTERS_MODULE,
      })
    );
  },
  [PATHS.CLUSTER_GROUPS.CREATE_GROUP]: () => {
    store.dispatch(initClusterGroupWizard());
  },
  [PATHS.CLUSTER_GROUPS.DETAILS_OVERVIEW]: async ({ id, tab }) => {
    await store.dispatch(getClusterGroupByUid(id));

    if (tab === "hostclusters") {
      store.dispatch(
        hostClustersListActions.initialize(HOST_CLUSTERS_LIST_MODULE)
      );
    }
    if (tab === "virtual-clusters") {
      store.dispatch(
        clusterGroupVirtualClustersListActions.initialize(
          CLUSTER_GROUP_VIRTUAL_CLUSTERS_LIST_MODULE
        )
      );
    }
    if (tab === "add-on-profiles") {
      await store.dispatch(getClusterGroupByUid(id));
      await store.dispatch(
        fetchClusterGroupProfiles({ withConfiguration: true })
      );
    }
  },
  [PATHS.WORKSPACES.WORKSPACE_DETAILS_OVERVIEW]: async ({
    id,
    flag: overviewTab,
  }) => {
    await store.dispatch(getWorkspaceByUid(id));

    store.dispatch(initializeWorkloadsByTab(overviewTab));

    const pollers = tasks.workspaceWorkloadPollersByTab[overviewTab];
    (pollers || []).forEach((polling) => polling.start());

    await store.dispatch(
      workloadsFilters.init({ module: WORKLOADS_FILTERS_MODULE })
    );

    if (WORKLOADS_WITH_METRICS.includes(overviewTab)) {
      store.dispatch(onWorkspacePodsPeriodChange("6 hours"));
    }
  },
  [PATHS.WORKSPACES.BACKUPS]: async ({ id, flag }) => {
    await store.dispatch(getWorkspaceByUid(id));

    if (flag === "restores") {
      await store.dispatch(
        workspaceRestoresListActions.initialize(WORKSPACE_RESTORE_LIST_MODULE)
      );
      tasks.pollWorkspacesRestoreList.start();
    }
    if (flag === "backups") {
      await store.dispatch(
        workspaceBackupsListActions.initialize(WORKSPACE_BACKUP_LIST_MODULE)
      );
      tasks.pollWorkspacesBackupList.start();
    }
  },
  [PATHS.CLUSTER_GROUPS.CLUSTER_GROUP_BACKUPS]: async ({ id, flag }) => {
    await store.dispatch(getClusterGroupByUid(id));

    if (flag === "restores") {
      await store.dispatch(
        clusterGroupRestoresListActions.initialize(
          CLUSTER_GROUP_RESTORE_LIST_MODULE
        )
      );
      tasks.pollClusterGroupsRestoreList.start();
    }
    if (flag === "backups") {
      await store.dispatch(
        clusterWorkspaceBackupsListActions.initialize(
          CLUSTER_WORKSPACE_BACKUP_LIST_MODULE
        )
      );
      tasks.pollClusterGroupsBackupList.start();
    }
  },
  [PATHS.CLUSTER_GROUPS.CREATE_VIRTUAL_CLUSTER]: async ({ id }) => {
    await store.dispatch(getClusterGroupByUid(id));
    store.dispatch(initVirtualClusterWizard());
  },
  [PATHS.CLUSTER_GROUPS.CLUSTER_GROUP_WORKLOADS_DETAILS]: async ({
    id,
    flag: workloadsTab,
  }) => {
    await store.dispatch(getClusterGroupByUid(id));

    store.dispatch(initializeClusterGroupWorkloadsByTab(workloadsTab));

    const pollers = tasks.clusterGroupWorkloadPollersByTab[workloadsTab];
    (pollers || []).forEach((polling) => polling.start());

    await store.dispatch(
      clusterGroupWorkloadsFilters.init({
        module: CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
      })
    );

    if (WORKLOADS_WITH_METRICS.includes(workloadsTab)) {
      store.dispatch(onClusterGroupPodsPeriodChange("6 hours"));
    }
  },
  [PATHS.MY_API_KEYS.ROOT]: () => {
    store.dispatch(apikeysListActions.initialize(API_KEYS_LIST_MODULE));
  },
  [PATHS.MY_API_KEYS.CREATE]: () => {
    store.dispatch(openApiKeyModal());
  },
  [PATHS.MY_API_KEYS.EDIT]: ({ uid }) => {
    store.dispatch(openApiKeyModal(uid));
  },

  [PATHS.VIRTUAL_CLUSTER.CREATE_CLUSTER]: async ({ id }) => {
    store.dispatch(sandboxClusterQuotaUsageFetcher.flushCache());
    store.dispatch(
      virtualClusterFormActions.init({ module: VIRTUAL_CLUSTER_FORM_MODULE })
    );
  },

  [PATHS.VIRTUAL_CLUSTER.OVERVIEW]: clusterOverview,
  [PATHS.VIRTUAL_CLUSTER.ROOT]: () => loadVirtualClusters(),
  [PATHS.APP_PROFILES.ROOT]: () =>
    store.dispatch(appProfilesListActions.initialize(APP_PROFILES_MODULE)),
  [PATHS.APP_PROFILES.CREATE]: () => {
    store.dispatch(helmRepositoriesFetcher.flushCache());
    applicationProfileCreator.initialize({
      toolbar: {
        activeSelection: "add",
        tabs: [
          {
            id: "add",
            icon: { awesome: faPlusCircle },
            onClick: () => {
              store.dispatch(operatorsFetcher.fetch());
              store.dispatch(containersFetcher.fetch());
              store.dispatch(messagingSystemsFetcher.fetch());
              store.dispatch(storageOptionsFetcher.fetch());
              store.dispatch(
                applicationProfileCreator.form.actions.validateForm()
              );
            },
            description: i18next.t("Add New Service"),
          },
        ],
      },
    });
    store.dispatch(appProfileMacrosFetcher.fetch());
    store.dispatch(operatorsFetcher.fetch());
    store.dispatch(containersFetcher.fetch());
    store.dispatch(messagingSystemsFetcher.fetch());
    store.dispatch(storageOptionsFetcher.fetch());
    store.dispatch(securityOptionsFetcher.fetch());
  },
  [PATHS.APP_PROFILES.DETAILS]: async ({ uid }) => {
    if (uid === "create") {
      return;
    }
    store.dispatch(helmRepositoriesFetcher.flushCache());
    store.dispatch(packsUrlFetcher.fetch());
    store.dispatch(appProfileMacrosFetcher.fetch());
    store.dispatch(initializeAppDetailPage({ uid }));
  },
  [PATHS.DEPLOYMENTS.ROOT]: async () => {
    await store.dispatch(
      appDeploymentsListActions.initialize(DEPLOYMENTS_LIST_MODULE)
    );
    tasks.pollAppDeployments.start();
  },
  [PATHS.DEPLOYMENTS.CREATE]: () => {
    store.dispatch(sandboxClusterQuotaUsageFetcher.flushCache());
    store.dispatch(initDeploymentCreationForm());
  },
  [PATHS.DEPLOYMENTS.OVERVIEW]: async ({ uid }) => {
    await store.dispatch(fetchAppDeploymentByUid(uid));
    tasks.pollAppDeploymentDetails.start();
    tasks.pollAppDeploymentNotifications.start();
  },
  [PATHS.REGISTRIES.ROOT]: ({ tab }) => {
    loadRegistries({ type: tab });
  },
  [PATHS.REGISTRIES.EDIT_REGISTRY]: ({ tab, uid }) => {
    if (tab === "helm") {
      store.dispatch(openHelmRegistryModal(uid));
    }
    if (tab === "oci") {
      store.dispatch(openOciRegistryModal(uid, "basic"));
    }
  },
  [PATHS.REGISTRIES.CREATE_REGISTRY]: ({ tab }) => {
    if (tab === "helm") {
      store.dispatch(openHelmRegistryModal());
    }
    if (tab === "oci") {
      store.dispatch(openOciRegistryModal());
    }
  },
  [PATHS.APP_MODE_OVERVIEW.ROOT]: async () => {
    try {
      store.dispatch({
        type: "SET_LOADING_APP_MODE_OVERVIEW",
        isLoading: true,
      });
      await store.dispatch(
        appDeploymentsListActionsForDashboard.initialize(
          DEPLOYMENTS_LIST_MODULE
        )
      );
      await store.dispatch(
        appProfilesListActions.initialize(APP_PROFILES_MODULE)
      );
      await store.dispatch(
        virtualClustersListActionsDashboard.initialize(
          VIRTUAL_CLUSTERS_LIST_MODULE
        )
      );
      await store.dispatch(
        developerQuotaUsageFetcherTenantScope.fetch("tenant")
      );
      await store.dispatch(
        developerQuotaUsageFetcherSystemScope.fetch("system")
      );
      await store.dispatch({
        type: "SET_LOADING_APP_MODE_OVERVIEW",
        isLoading: false,
      });
    } catch (e) {
      await store.dispatch({
        type: "SET_LOADING_APP_MODE_OVERVIEW",
        isLoading: false,
      });
    }

    tasks.pollSpectroClusters.start();
    tasks.pollAppDeployments.start();
    tasks.pollVirtualClusters.start();
  },
};

// NOTE:
// the argument location contains the path you are going to navigate
// the state location contains the current path you are on
history.block((location) => {
  const state = store.getState();

  if (location.pathname.includes("auth")) {
    return;
  }

  if (
    history.location.pathname.includes("/profiles/") &&
    state.location?.params?.id &&
    state.location?.params?.id !== "create"
  ) {
    const profileModules = {
      system: systemProfileBuilderEditModule,
      cluster: profileBuilderEditModule,
    };

    const currentModule = profileModules[state.location?.params?.tab];

    const { getPackErrors, hasIncompleteLayers, hasUnsavedChanges } =
      currentModule.selectors;

    const hasErrors = getPackErrors(state).length !== 0;
    if (
      !deleteProfileModal.isOpened() &&
      (hasUnsavedChanges(state) || hasIncompleteLayers(state) || hasErrors)
    ) {
      return i18next.t(
        "Are you sure you want to leave without saving your changes?"
      );
    }
  }

  if (
    history.location.pathname.includes("/app-profiles/") &&
    state.location?.params?.uid &&
    state.location?.params?.uid !== "create"
  ) {
    if (hasAppProfileUnsavedChanges(state)) {
      return i18next.t(
        "Are you sure you want to leave without saving your changes?"
      );
    }
  }
});

async function scanDetailsTabObserver({ id, scanUid, scanType }) {
  await store.dispatch(getClusterByUid(id));
  store.dispatch(loadClusterScan(scanType, scanUid));
}

function certificatesObserver({ id }) {
  store.dispatch(getClusterByUid(id));
  store.dispatch(certsListActions.initialize(CERTS_MODULE));
}

async function clusterDetailsObserver({
  clusterCategory,
  tab,
  clusterGroupId,
  id,
  flag,
}) {
  store.dispatch(nonFipsSettingsFetcher.fetch());
  const cluster = getCluster(store.getState());

  if (cluster?.metadata?.uid !== id) {
    store.dispatch(
      DeviceSelection.services.listingActions.clearPersistedFilters(
        DeviceSelection.moduleName
      )
    );
  }

  if (clusterCategory === "clustergroups") {
    await store.dispatch(fetchAllDashboardClusters.fetch());
    await store.dispatch(getClusterGroupByUid(clusterGroupId));
  }

  if (tab === "overview") {
    closeKubectlModal();
    clusterOverview({ id, clusterCategory });
  }

  if (tab === "appliances") {
    await store.dispatch(fetchClusterAndNodes(id));
    await store.dispatch(
      appliancesListActions.initialize("cluster-appliances")
    );
  }

  if (tab === "configure") {
    await store.dispatch(getClusterByUid(id));
    store.dispatch(clusterRevisionsFetcher.key(id).fetch());
    await store.dispatch(fetchClusterProfile({ withConfiguration: true }));

    const attachedProfileUid = history.getQuery()?.attachedProfile;
    if (attachedProfileUid) {
      await store.dispatch(attachClusterProfile(attachedProfileUid));
    }
  }

  if (tab === "vms") {
    await store.dispatch(getClusterByUid(id));
  }

  if (tab === "costs") {
    await store.dispatch(getClusterByUid(id));
    store.dispatch(initClusterCosts());
  }

  if (tab === "workloads") {
    await store.dispatch(fetchClusterAndNodes(id));
    store.dispatch(fetchClusterEstimatedRate());
    store.dispatch(initializeWorkloadsTab());
    store.dispatch(namespacesNamesFetcher.fetch());

    if (flag === "virtual-clusters") {
      store.dispatch(
        virtualClustersListingActions.initialize(
          WORKLOAD_VIRTUAL_CLUSTERS_MODULE
        )
      );
    }

    const pollers = tasks.clusterWorkloadPollersByTab[flag];
    (pollers || []).forEach((polling) => polling.start());
    await store.dispatch(
      workloadsFilterFormActions.init({ module: "workloadsFilters" })
    );

    if (WORKLOADS_WITH_METRICS.includes(flag)) {
      store.dispatch(onChartPeriodChange("6 hours"));
    }
  }

  if (tab === "nodes") {
    await store.dispatch(fetchClusterAndNodes(id));
    store.dispatch(fetchRepaveStatus(id));
    const cluster = getCluster(store.getState());

    if (["aks", "coxedge"].includes(cluster?.spec?.cloudType)) {
      store.dispatch(fetchClusterProfile());
    }

    store.dispatch(fetchClusterEstimatedRate());
    tasks.pollNodes.start();
  }

  if (tab === "events") {
    await store.dispatch(fetchClusterAndPollEvents(id));
    store.dispatch(clusterAppliancesFetcher.fetch());
  }

  if (tab === "scans") {
    await store.dispatch(getClusterByUid(id));
    await store.dispatch(scansCountFetcher.key(id).fetch());
    watchScansTask.start();
  }

  if (tab === "backups") {
    await store.dispatch(getClusterByUid(id));

    if (flag === "restores") {
      await store.dispatch(restoresListActions.initialize(RESTORE_LIST_MODULE));
      tasks.pollRestoreList.start();
    }
    if (flag === "backups") {
      await store.dispatch(backupsListActions.initialize(BACKUP_LIST_MODULE));
      tasks.pollBackupList.start();
    }
  }
}

function cleanUp() {
  api.stopCollect();
  stopAllPollingTasks();
  api.abortAllPending();
}

function stopAllPollingTasks() {
  Object.keys(tasks).forEach((key) => {
    if (key === "pollClusterLogStatus") {
      return;
    }
    tasks[key]?.stop?.();
  });
}

function getLocationMatch(path, location) {
  let match = matchPath(location.pathname, {
    path,
    exact: true,
    strict: true,
  });

  if (!match) {
    match = matchPath(location.pathname, {
      path: `${PROJECT_PREFIX}${path}`,
      exact: true,
      strict: true,
    });
  }

  if (!match) {
    match = matchPath(location.pathname, {
      path: `${ADMIN_PREFIX}${path}`,
      exact: true,
      strict: true,
    });
  }

  if (!match) {
    match = matchPath(location.pathname, {
      path: `${ADMIN_PREFIX_WITH_PROJECT}${path}`,
      exact: true,
      strict: true,
    });
  }

  if (!match) {
    match = matchPath(location.pathname, {
      path: `${DEV_PREFIX_WITH_PROJECT}${path}`,
      exact: true,
      strict: true,
    });
  }

  return match;
}

function onLocationChange(location, action) {
  if (action === "REPLACE") return;

  cleanUp();

  const projectMatch = matchPath(history.location.pathname, {
    path: "/projects/:projectUid/*",
    exact: true,
  });

  const devProjectMatch = matchPath(history.location.pathname, {
    path: `${DEV_PREFIX_WITH_PROJECT}/*`,
    exact: true,
  });

  if (projectMatch) {
    store.dispatch(selectProjectFromRoute(projectMatch.params));
  }

  if (history.location.pathname.startsWith("/admin/")) {
    store.dispatch(selectProjectFromRoute({ projectUid: ADMIN_GUID }));
  }

  if (history.location.pathname.startsWith("/dev/") && devProjectMatch) {
    store.dispatch(selectProjectFromRoute(devProjectMatch.params));
  }

  Object.keys(MAP).forEach((path) => {
    const match = getLocationMatch(path, location);

    if (match) {
      store.dispatch({
        type: "UPDATE_ROUTER_PARAMS",
        params: match.params,
        path,
        route: getPathForValue(PATHS, path),
      });
      MAP[path](match.params);
    }
  });
}

function startListening() {
  return history.listen(onLocationChange);
}

let listener = null;
export default function observeHistory() {
  listener = startListening();
  onLocationChange(history.location);
}

export function stopListening() {
  listener();
}
